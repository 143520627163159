/** This module provides functions to decode and encode config tokens. */

import {z, ZodType} from "zod";

/**
 * Decodes a token into an object.
 * @param token - The config token to decode.
 * @param type - The validation schema for the config object.
 * @returns The decoded object.
 */
export function decodeConfigToken<T extends ZodType>(token: string, type: T): z.infer<T> {
    const base64Url = token;
    const base64 = base64Url.replaceAll("-", "+").replaceAll("_", "/");
    const jsonEncoded = Uint8Array.from(atob(base64).split("").map((c) => c.charCodeAt(0))); // utf-8 encoded
    const json = new TextDecoder().decode(jsonEncoded);
    const value = JSON.parse(json);
    return type.parse(value);
}

/**
 * Encodes an object into a token.
 * @param payload - The object to encode into a token.
 * @returns The encoded token.
 */
export function encodeConfigToken(payload: any): string {
    const json = JSON.stringify(payload);
    const jsonEncoded = new TextEncoder().encode(json);
    const base64 = btoa(String.fromCharCode(...jsonEncoded));
    const base64url = base64.replaceAll("/", "_").replaceAll("+", "-").replace(/=+$/, "");
    return base64url;
}