exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dev-settings-tsx": () => import("./../../../src/pages/dev-settings.tsx" /* webpackChunkName: "component---src-pages-dev-settings-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-patients-index-tsx": () => import("./../../../src/pages/patients/index.tsx" /* webpackChunkName: "component---src-pages-patients-index-tsx" */),
  "component---src-pages-patients-tsx": () => import("./../../../src/pages/patients/[...].tsx" /* webpackChunkName: "component---src-pages-patients-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings/[...].tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-tsx": () => import("./../../../src/pages/[...].tsx" /* webpackChunkName: "component---src-pages-tsx" */),
  "component---src-pages-tutorial-tsx": () => import("./../../../src/pages/tutorial.tsx" /* webpackChunkName: "component---src-pages-tutorial-tsx" */)
}

