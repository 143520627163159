import React, {useEffect} from 'react';
import {App, URLOpenListenerEvent} from '@capacitor/app'
import Cookies from "js-cookie";
import {navigate} from "gatsby";

/**
 * This component sets the token "cookie" at the launch of the Capacitor app,
 * using the first segment of the URL path as the value.
 * The "token" enables access to the mock server.
 *
 * @note
 * It operates correctly only with API URLs having a `.dev` TLD.
 *
 * @note
 * This functionality is a duplication of that found in `src/pages/[...].tsx.
 *`
 * @component
 */
const AppUrlListener: React.FC<any> = () => {
    useEffect(() => {
        if (process.env.GATSBY_ACTIVE_ENV === "capacitor") {
            App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
                const params = event.url.split('.dev')[1].split(`/`)

                console.log(params)
                console.log(params[1])

                params[1] && (
                    Cookies.set("token", params[1])
                )
                await navigate(`/${params.splice(1).join("/")}${location.search}${location.hash}`, {replace: true})
            });
        }
    }, []);

    return null;
};

export default AppUrlListener;